import { boot } from "quasar/wrappers";
import moment from "moment";
import money from 'v-money3';
import {syncOrderStatues} from "src/store/data/actions";
import {useQuasar} from "quasar";

export const $app = {
  STATES_OPTIONS: [
    {
      label: "Alabama",
      value: "AL",
    },
    {
      label: "Alaska",
      value: "AK",
    },
    {
      label: "American Samoa",
      value: "AS",
    },
    {
      label: "Arizona",
      value: "AZ",
    },
    {
      label: "Arkansas",
      value: "AR",
    },
    {
      label: "California",
      value: "CA",
    },
    {
      label: "Colorado",
      value: "CO",
    },
    {
      label: "Connecticut",
      value: "CT",
    },
    {
      label: "Delaware",
      value: "DE",
    },
    {
      label: "District Of Columbia",
      value: "DC",
    },
    {
      label: "Federated States Of Micronesia",
      value: "FM",
    },
    {
      label: "Florida",
      value: "FL",
    },
    {
      label: "Georgia",
      value: "GA",
    },
    {
      label: "Guam",
      value: "GU",
    },
    {
      label: "Hawaii",
      value: "HI",
    },
    {
      label: "Idaho",
      value: "ID",
    },
    {
      label: "Illinois",
      value: "IL",
    },
    {
      label: "Indiana",
      value: "IN",
    },
    {
      label: "Iowa",
      value: "IA",
    },
    {
      label: "Kansas",
      value: "KS",
    },
    {
      label: "Kentucky",
      value: "KY",
    },
    {
      label: "Louisiana",
      value: "LA",
    },
    {
      label: "Maine",
      value: "ME",
    },
    {
      label: "Marshall Islands",
      value: "MH",
    },
    {
      label: "Maryland",
      value: "MD",
    },
    {
      label: "Massachusetts",
      value: "MA",
    },
    {
      label: "Michigan",
      value: "MI",
    },
    {
      label: "Minnesota",
      value: "MN",
    },
    {
      label: "Mississippi",
      value: "MS",
    },
    {
      label: "Missouri",
      value: "MO",
    },
    {
      label: "Montana",
      value: "MT",
    },
    {
      label: "Nebraska",
      value: "NE",
    },
    {
      label: "Nevada",
      value: "NV",
    },
    {
      label: "New Hampshire",
      value: "NH",
    },
    {
      label: "New Jersey",
      value: "NJ",
    },
    {
      label: "New Mexico",
      value: "NM",
    },
    {
      label: "New York",
      value: "NY",
    },
    {
      label: "North Carolina",
      value: "NC",
    },
    {
      label: "North Dakota",
      value: "ND",
    },
    {
      label: "Northern Mariana Islands",
      value: "MP",
    },
    {
      label: "Ohio",
      value: "OH",
    },
    {
      label: "Oklahoma",
      value: "OK",
    },
    {
      label: "Oregon",
      value: "OR",
    },
    {
      label: "Palau",
      value: "PW",
    },
    {
      label: "Pennsylvania",
      value: "PA",
    },
    {
      label: "Puerto Rico",
      value: "PR",
    },
    {
      label: "Rhode Island",
      value: "RI",
    },
    {
      label: "South Carolina",
      value: "SC",
    },
    {
      label: "South Dakota",
      value: "SD",
    },
    {
      label: "Tennessee",
      value: "TN",
    },
    {
      label: "Texas",
      value: "TX",
    },
    {
      label: "Utah",
      value: "UT",
    },
    {
      label: "Vermont",
      value: "VT",
    },
    {
      label: "Virgin Islands",
      value: "VI",
    },
    {
      label: "Virginia",
      value: "VA",
    },
    {
      label: "Washington",
      value: "WA",
    },
    {
      label: "West Virginia",
      value: "WV",
    },
    {
      label: "Wisconsin",
      value: "WI",
    },
    {
      label: "Wyoming",
      value: "WY",
    },
  ],
  AVAILABLE_STATES_OPTIONS: [
    {
      label: "Colorado",
      value: "CO",
    },
  ],
  COMPANY_USER_ROLES_OPTIONS: [
    {
      label: "Owner",
      value: "owner",
    },
    {
      label: "Staff",
      value: "staff",
    },
  ],
  UNIT_TYPES_OPTIONS: [
    {
      label: "Unit(s)",
      value: "unit",
    },
    {
      label: "lb",
      value: "lb",
    },
    {
      label: "g",
      value: "g",
    },
    {
      label: "mg",
      value: "mg",
    },
    {
      label: "kg",
      value: "kg",
    },
    {
      label: "l",
      value: "l",
    },
    {
      label: "ml",
      value: "ml",
    },
  ],
  ORDER_STATUSES: [
    {
      label: "All",
      value: "",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Declined",
      value: "declined",
    },
    {
      label: "Accepted",
      value: "accepted",
    },
  ],
  METRC_PACKAGES_DAYS_TO_PULL: process.env.DAYS_METRC_PACKAGES_LIST,

  getFormattedDate(dateValue) {
    let date = moment(dateValue);

    return date.isValid() ? date.format("L") : "";
  },
  getFormattedDateTime(dateTimeValue) {
    let date = moment(dateTimeValue);

    return date.isValid() ? date.format("L LT") : "";
  },
  getFormattedDateHuman(dateTimeValue) {
    let date = moment(dateTimeValue);

    return date.isValid()
      ? date.fromNow().replace(/^in /, "").replace(/^an?/, 1)
      : "";
  },
  getTodayDateString() {
    return moment().format("YYYY/MM/DD");
  },
  getDateDifference(dateString, dateStringCompare = null) {
    let date = moment(dateString),
      now = dateStringCompare ? moment(dateStringCompare) : moment(),
      duration = moment.duration(date.diff(now));

    return {
      isPast: Math.abs(date.isBefore(now)),
      days: Math.abs(Math.trunc(duration.asDays())),
      hours: Math.abs(duration.hours()),
      minutes: Math.abs(duration.minutes()),
    };
  },
  getHumanReadableTimePast(dateString, dateStringCompare = null, onlyHighest = false) {
    let diff = this.getDateDifference(dateString, dateStringCompare),
      timeParts = [];

    if (!diff.isPast) {
      return "";
    }

    if (diff.days !== 0) {
      if (onlyHighest) {
        return `${diff.days} ` + (diff.days > 1 ? 'days' : 'day') + ' ago';
      }
      timeParts.push(`${diff.days} ` + (diff.days > 1 ? 'days' : 'day'));
    }
    if (diff.hours !== 0) {
      if (onlyHighest) {
        return `${diff.hours} ` + (diff.hours > 1 ? 'hours' : 'hour') + ' ago';
      }
      timeParts.push(`${diff.hours} ` + (diff.hours > 1 ? 'hours' : 'hour'));
    }
    if (diff.minutes !== 0) {
      if (onlyHighest) {
        return `${diff.minutes} ` + (diff.minutes > 1 ? 'minutes' : 'minute') + ' ago';
      }
      timeParts.push(`${diff.minutes} ` + (diff.minutes > 1 ? 'minutes' : 'minute'));
    }

    return timeParts.join(", ") + (timeParts.length > 0 ? ' ago' : '');
  },
  convertFromUtc(dateValue, format = "YYYY-MM-DD HH:mm:ss") {
    return moment.utc(dateValue).local().format(format);
  },
  getFormattedState(stateValue) {
    let state = this.STATES_OPTIONS.find((state) => state.value === stateValue);

    return state ? state.label : stateValue;
  },
  getFormattedUnitType(unitTypeValue) {
    if (unitTypeValue === 'ea') {
      return 'Each';
    }
    let unitType = this.UNIT_TYPES_OPTIONS.find(
      (unitType) => unitType.value === unitTypeValue
    );

    return unitType ? unitType.label : unitTypeValue;
  },
  getFormattedUnitTypeListing(listing) {
    if (listing?.category_type.id === 1) return listing.case_quantity;
    if (listing?.retail_type === 'packaged') return listing.packaged_qty_value + listing.packaged_qty_unit;
    return this.getFormattedUnitType(listing.inventory_unit);
  },
  getFormattedPrice(price) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price);
  },
  getFormattedNumber(number, minDecimals = 0) {
    return new Intl.NumberFormat("en-US", {minimumFractionDigits: minDecimals}).format(number);
  },
  getFormattedQty(qty, unit, separator = ' ') {
    if (unit !== 'ea') {
      qty = this.getFormattedNumber(qty, 2);
    }

    return `${qty}${separator}${unit}`;
  },
  getFormattedThcCbd(thcCbdValue, thcCbdMax, thcCbdUnit) {
    if (thcCbdValue || thcCbdMax) {
      if (thcCbdValue && thcCbdMax) {
        return `${thcCbdValue}-${thcCbdMax}${thcCbdUnit}`;
      }
      if (thcCbdValue) {
        return `${thcCbdValue}${thcCbdUnit}`;
      }
      if (thcCbdMax) {
        return `${thcCbdMax}${thcCbdUnit}`;
      }
    }

    return 'N/A';
  },
  getCardValidThru(cardExpires) {
    let date = moment(cardExpires);

    return date.isValid() ? date.format('M/YY') : cardExpires;
  },
  sortingDateFunc(first, second, ascending = true) {
    let momentFirst = moment(first),
      momentSecond = moment(second);

    if (momentFirst.isValid() && momentSecond.isValid()) {
      if (momentFirst.isBefore(momentSecond)) {
        return ascending ? -1 : 1;
      }
      if (momentFirst.isAfter(momentSecond)) {
        return ascending ? 1 : -1;
      }
    }

    return 0;
  },
  sortingStrFunc(first, second, ascending = true) {
    if (first < second) {
      return ascending ? -1 : 1;
    }
    if (first > second) {
      return ascending ? 1 : -1;
    }
    return 0;
  },
};

export default boot(({ app, store, router }) => {
  app.use(money);

  if (store.state.auth.is_user_logged_in) {
    //Sync company .
    store.dispatch("data/syncCompany");
    //Sync company licenses.
    store.dispatch("data/syncLicenses");
    //Sync payment methods.
    store.dispatch("data/syncPaymentMethods");
    //Sync company contacts.
    store.dispatch("data/syncCompanyContacts");
    //Sync user notifications.
    store.dispatch("notifications/sync");
    //Sync app taxonomies.
    store.dispatch("data/syncTaxonomies");
    //Sync count of active listings in user's watchlist.
    store.dispatch("auth/syncActiveWatchlistCount");
    //Sync order statues.
    store.dispatch("data/syncOrderStatues");
    //Sync order sources.
    store.dispatch("data/syncOrderSources");
    //Sync order types.
    store.dispatch("data/syncOrderTypes");
  }

  //$app mix-in
  app.config.globalProperties.$app = $app;

  // findParent
  app.config.globalProperties.findParent = function(name) {
    let $p = this.$parent;

    while ($p) {
      if ($p.$.type.name == name) break;
      $p = $p.$parent;
    }

    return $p;
  };

  //loadForm
  app.config.globalProperties.loadForm = function (name, id) {
    //loop through keys to make things dynamic
    const obj = Object.keys(store.state[name].form).reduce((obj, prop) => {
      //add the properties to the proxy object
      Object.defineProperty(obj, prop, {
        get() {
          return store.state[name].form[prop];

          //return store.getters[name + "/getFormValue"](prop);
        },
        set(value) {
          //saving is different depending on what type
          if (value instanceof File) {
            store.dispatch(name + '/uploadFormFile', { prop, value });
          } else if (['image', 'auction_brand_logo', 'buyer_request_image'].includes(prop) && value === null) {
            store.dispatch(name + '/deleteFormFile', { prop });
          } else if (['images', 'auction_images'].includes(prop)) {
            store.dispatch(name + '/uploadFormImages', { prop, value });
          } else {
            store.commit(name + "/setFormValue", { prop, value });
          }
        }
      });

      // return proxy object
      return obj;
    }, {});

    //form getter
    obj.data = () => {
      return store.getters[name + '/getFormData'];
    }

    //form clear
    obj.clear = () => {
      store.commit(name + "/clearFormData");
    }

    obj.request_error = null;

    //form loader
    obj.load = (id) => {
      store.dispatch(name + "/loadFormData", id)
        .then((data) => {
          if (typeof data.request_error !== 'undefined') {
            obj.request_error = data.request_error;
          } else {

          }
          return obj;
          // if (data.id != id) {
          //   throw 'loadFailed';
          // }
        });
    }

    //form submitter
    obj.submit = (extraData) => {
      if (!extraData || typeof extraData !== 'object') {
        extraData = {};
      }
      return store.dispatch(name + "/submitFormData", extraData);
    }

    //either load the id or clear the form if needed
    if (id) {
      if (id != obj.id) {
        obj.load(id);
      }
    } else if (obj.id) {
      obj.clear();
    }

    return obj;
  };
});
